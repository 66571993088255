<!--
 * @Author: Leif
 * @Date: 2021-11-05 13:44:27
 * @LastEditTime: 2022-09-07 21:56:49
 * @LastEditors: ch3nh2
 * @Description: 会议调度 - 会议发起
 * @FilePath: /zhy_dataview_fe/src/pages/MeetingSchedule/Initiate.vue
-->
<template>
  <div class="address_book el-collapse-container">
    <template v-if="flag">
      <div style="
          width: 100%;
          height: 93%;
          bottom: 0px;
          position: absolute;
          background-color: #333;
          filter: Alpha(Opacity=60);
          opacity: 0.6;
          z-index: 9999;
        "></div>
    </template>
    <Navigation />
    <el-container>
      <el-aside class="leftaside" :class="{ 'left-collapse': isLeftCollapse }">
        <i class="el-arrow" @click="isLeftCollapse = !isLeftCollapse"></i>
        <div class="sidebar">
          <el-tabs v-model="leftActiveName" class="general_tabs">
            <el-tab-pane label="通讯录" name="addressBook">
              <div class="addressBook_area">
                <AddressBookList ref="addressBook" @checkedUser="checkedUser" />
              </div>
            </el-tab-pane>
            <!-- <el-tab-pane label="监控设备列表" name="monitoringAidsList">
              <div class="addressBook_area">
                <MonitoringList ref="monitoringList" @checkedVideo="checkedVideo" />
              </div>
            </el-tab-pane> -->
          </el-tabs>
        </div>
      </el-aside>
      <el-main>
        <div class="participants_list">
          <ul v-if="participantsList.length > 0">
            <li v-for="(item, index) in participantsList" :key="index">
              <div class="participantsItem">
                <div class="close" @click="removeParticipants(item)"></div>
                <div class="avatar">
                  <img :src="
                    item.type == 'STAFF'
                      ? require('assets/icons/icon_user.png')
                      : require('assets/icons/video_icon.png')
                  " alt="" />
                </div>
                <div class="user_name">{{ item.name }}</div>
                <div class="user_phone">{{ item.numberInfo.length > 0 ? item.numberInfo[0].userNumber : item.account }}
                </div>
              </div>
            </li>
          </ul>
          <div class="nodata-wrap" v-else>
            <p class="nodata-text">当前暂未选择联系人</p>
            <p class="nodata-text">可在左侧选择联系人并对其发起会议邀请</p>
          </div>
        </div>
      </el-main>
      <el-aside class="rightaside" :class="{ 'right-collapse': isRightCollapse }">
        <i class="el-arrow" @click="isRightCollapse = !isRightCollapse"></i>
        <div class="sidebar">
          <el-tabs v-model="rightActiveName" class="general_tabs">
            <el-tab-pane label="参会记录" name="groupWill">
              <div class="InMeeting">
                <MeetingGroupList ref="meetingRecord" optType="groupWill" />
              </div>
            </el-tab-pane>
            <el-tab-pane label="快捷会议" name="join">
              <div class="quickMeeting">
                <MeetingGroupList optType="join" />
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-aside>
    </el-container>
    <div class="initiate_btn" @click="postMetting">发起会议</div>
  </div>
</template>

<script>
import AddressBookList from "components/InsidePages/AddressBookList";
import MonitoringList from "components/InsidePages/MonitoringList";
import MeetingGroupList from "components/InsidePages/MeetingGroupList";
import Navigation from "components/InsidePages/Navigation";
import { ElMessage } from "element-plus";

export default {
  name: "AddressBook",
  data() {
    return {
      userList: [],
      videoList: [],
      participantsList: [],
      EventLogTran: false,
      leftActiveName: "addressBook",
      rightActiveName: "groupWill",
      isLeftCollapse: false,
      isRightCollapse: false,
      flag: false,
    };
  },
  computed: {},
  components: {
    Navigation,
    AddressBookList,
    MonitoringList,
    MeetingGroupList,
  },
  props: {},
  methods: {
    async postMetting() {
      const userList = [];
      const userArr = [];
      const numArr = [];
      if (this.userList.length == 0) {
        this.$utils.errorAlert("当前未选择任何联系人", "不能发起会议");
        return
      }
      this.$utils.showLoading({ text: '正在发起会议，请稍后...' });
      this.userList.map((item) => {
        userList.push(`${item.name},${item.numberInfo.length > 0 ? item.numberInfo[0].userNumber : item.account}`)
        userArr.push(item.name)
        numArr.push(item.account)
      })
      await this.$api.addJoinRecord({
        name: '会议记录',
        recordType: 2,
        meetingSubject: JSON.stringify(userArr),
        meetingNumber: JSON.stringify(numArr)
      })
      await this.$refs.meetingRecord.getJoinRecords(true);
      this.$JX.vStartConference(userList.join(';'), 1);
      this.$utils.hideLoading()
      // 亿联版本（弃用）
      // const $this = this;
      // const { userInfo } = this.$store.state;
      // const { showLoading, hideLoading } = this.$utils;
      // const userArr = [];
      // const rtspUsers = [];
      // showLoading();
      // this.userList.map((item) => {
      //   userArr.push({
      //     uid: item.staffId,
      //     type: "audio-video",
      //   });
      // });
      // this.videoList.map((item) => {
      //   rtspUsers.push(item.rtspUrl);
      // });
      // this.$api
      //   .postMeetingNow({
      //     uid: userInfo.staffId,
      //     type: "audio-video",
      //     users: userArr,
      //     rtspUsers,
      //   })
      //   .then((res) => {
      //     const { data } = res;
      //     if (data) {
      //       $this.$router.push({
      //         name: "inMeeting",
      //         query: {
      //           conferenceNumber: res.data.conferenceNumber,
      //           conferencePassword: res.data.conferencePassword,
      //         },
      //       });
      //     }
      //   })
      //   .finally(() => {
      //     hideLoading();
      //   });
    },
    checkedUser(arr) {
      this.userList = arr;
      this.checkedNodes();
    },
    checkedVideo(arr) {
      this.videoList = arr;
      this.checkedNodes();
    },
    checkedNodes() {
      this.participantsList = [...this.userList, ...this.videoList];
    },
    removeParticipants(item) {
      this.userList = this.userList.filter((t) => t.id != item.id);
      this.videoList = this.videoList.filter((t) => t.id != item.id);
      this.checkedNodes();
      this.$refs.addressBook.removeCheck(this.participantsList);
      this.$refs.monitoringList.removeCheck(this.participantsList);
    },
  },
  created() {
    var showflag = localStorage.getItem("ACCESS_PERMISSIONS");
    if (showflag.split(",").length >= 1) {
      if (showflag.split(",").findIndex((item) => item == "b004") >= 1) {
        this.flag = false;
      } else {
        ElMessage.error("暂无权限！");
      }
    } else {
      ElMessage.error("暂无权限！");
    }
  },
  mounted() { },
  beforeUnmount() { },
};
</script>

<style lang="scss">
.address_book {
  position: relative;

  .sidebar {
    background: #202331;
    height: 892px;
  }
}

.participants_list {
  background: #202331;
  height: 640px;
  padding: 20px;
  overflow-y: scroll;
  overflow-x: hidden;

  .nodata-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .nodata-text {
      font-size: 18px;

      &:nth-child(1) {
        margin-bottom: 25px;
      }
    }
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-right: -25px;
    margin-bottom: -25px;

    li {
      width: 154px;
      margin-right: 25px;
      border: 1px solid #464d6f;
      margin-bottom: 25px;
      height: 140px;
      text-align: center;
      color: rgba(255, 255, 255, 0.6);
      position: relative;
      transition: all 0.2s ease-out;
      cursor: pointer;
      background: #202331;

      .avatar {
        width: 52px;
        height: 52px;
        background: #30354d;
        border-radius: 100%;
        overflow: hidden;
        margin: 15px auto;

        img {
          width: 100%;
        }
      }

      .user_name {
        margin-bottom: 10px;
      }

      .close {
        position: absolute;
        width: 24px;
        height: 24px;
        background: url("../../assets/icons/close_icon.png") no-repeat;
        background-size: 100%;
        right: 5px;
        top: 5px;
        display: none;
      }

      &:hover {
        transform: scale(1.05);
        box-shadow: 0 0 30px 4px rgba(0, 0, 0, 0.6);

        .close {
          display: block;
        }
      }
    }
  }
}

.initiate_btn {
  position: absolute;
  width: 280px;
  height: 78px;
  background: rgba(73, 99, 164, 0.7);
  border-radius: 39px;
  z-index: 0;
  bottom: 50px;
  left: 50%;
  margin-left: -140px;
  font-size: 30px;
  color: #ffffff;
  text-align: center;
  line-height: 78px;
  transition: all 0.2s ease-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 0 30px 4px rgba(0, 0, 0, 0.6);
  }
}

.el-tree-node:focus>.el-tree-node__content {
  background-color: transparent;
}

.participantsItem {
  font-size: 16px;
}

.el-collapse-container {
  .el-tabs__nav-wrap {
    padding: 0;

    .el-tabs__nav-prev,
    .el-tabs__nav-next {
      display: none;
    }
  }

  .el-aside {
    width: 22%;
    transition: all 0.3s;
    -webit-transition: all 0.3s;
    position: relative;
    overflow: visible;
  }

  .el-aside::-webkit-scrollbar {
    width: 0;
  }

  .el-main {
    padding: 0;
  }

  .leftaside {
    margin-right: 1.5%;
    cursor: pointer;

    .el-arrow {
      right: -26px;
    }
  }

  .rightaside {
    margin-left: 1.5%;
    cursor: pointer;

    .el-arrow {
      left: -26px;
      transform: rotate(180deg);
    }
  }

  .left-collapse {
    width: 0%;
    transform-origin: 0% 0%;

    .el-arrow {
      transform: rotate(180deg);
    }
  }

  .right-collapse {
    width: 0%;
    transform-origin: 100% 0%;

    .el-arrow {
      transform: rotate(0deg);
    }
  }

  .el-arrow {
    display: block;
    width: 26px;
    height: 26px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../../assets/image/pages/commom/arrow.png");
    position: absolute;
    top: 50%;
    margin-top: -13px;
    z-index: 2;
  }
}
</style>
